var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    _vm.componentClass,
    _vm.disabledClass,
    {
      'no-text': !_vm.text
    }
],on:{"click":_vm.handleOnClick}},[_c('font-awesome-icon',{class:`${_vm.componentClass}__icon`,attrs:{"icon":_vm.icon}}),(_vm.text)?_c('p',{class:`text-${_vm.text.color} ${_vm.componentClass}__text`},[_vm._v(" "+_vm._s(_vm.text.text)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }